<script>
    export let onAsk;

    function ask(event) {
        const text = prompt("Nouveau message:");
        onAsk(text.toUpperCase());
    }
</script>

<button on:click={ask} />

<style>
    button {
        width: 100%;
        height: 3vh;
        background-color: #2980b9;
        border: none;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
    }
</style>
