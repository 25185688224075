<script>
	import { onMount } from "svelte";
	import Config from "./Config.svelte";

	let videoEl;

	onMount(() => {
		navigator.mediaDevices
			.getUserMedia({ video: true })
			.then((stream) => {
				// Utilisez le flux vidéo pour afficher la vidéo dans un élément <video>
				videoEl.srcObject = stream;
				videoEl.play();
			})
			.catch((err) => {
				console.error(err);
			});
	});

	let captionText = "";

	onMount(() => {
		captionText = localStorage.getItem("captionTxt") ?? "";
	});

	function setText(txt) {
		captionText = txt;
		localStorage.setItem("captionTxt", txt);
	}
</script>

<Config onAsk={setText} />
<main>
	<div>
		<video bind:this={videoEl}>
			Votre navigateur ne supporte pas la vidéo
		</video>
	</div>
	<p>{captionText}</p>
</main>

<style>
	:global(body) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
		margin: 0;
		height: 100vh;
		overflow-y: auto;
		background: #2c3e50;
	}
	main {
		position: relative;
		background: #34495e;

		width: 90vw;
		height: 90vh;
		border-radius: 3vh;
		overflow: hidden;
		margin-bottom: 5vh;
	}
	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}
	p {
		position: absolute;
		top: 0;
		width: 100%;
		font-size: 10vw;
		text-align: center;

		color: white;
		text-shadow: -0.1em -0.1em 0 black;
	}
</style>
